export function normalizeAnnotations(
  annotations = [],
  { isPublicAnnotation = false } = {},
) {
  annotations = Array.isArray(annotations) ? annotations : [annotations];

  const privateAnnotationsMapper = ({
    section_type: sectionType,
    trial_id: trialId,
    created_by_me: createdByMe,
    responses,
    ...rest
  }) => ({
    ...rest,
    sectionType,
    trialId,
    createdByMe,
    responses: normalizeAnnotationResponses(responses),
  });

  const publicAnnotationsMapper = ({
    section_type: sectionType,
    trial_id: trialId,
    created_by_me: createdByMe,
    user_name: userName,
    user_image: userImage,
    account_name: accountName,
    account_image: accountImage,
    is_public: isPublic,
    ...rest
  }) => ({
    ...rest,
    sectionType,
    trialId,
    createdByMe,
    userName,
    userImage,
    accountName,
    accountImage,
    isPublic,
  });

  return annotations.map(
    isPublicAnnotation ? publicAnnotationsMapper : privateAnnotationsMapper,
  );
}

export function normalizeAnnotationResponses(responses = []) {
  responses = Array.isArray(responses) ? responses : [responses];

  return responses.map(({ created_by_me: createdByMe, ...rest }) => ({
    ...rest,
    createdByMe,
  }));
}
