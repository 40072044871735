import { geoEquirectangular, geoPath } from "d3-geo";
import { select } from "d3-selection";
import { json } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import { smoothScrollTo } from 'commons/utils/scroll';
import AOS from 'aos';
$(document).ready(function () {
  const $scrollDownButton = document.querySelector('.scroll-down-button')
  const map_color = "#b6b6b6";
  const countries_json = window.__COUNTRIES_DATA__;
  const map_data = window.__MAP_DATA__;
  const max_radius =  window.__MAP_MAX_RADIUS__;
  const max_trials = window.__MAX_TRIALS__
  const w = 1000;
  const h = 500;
  const trialMaxItem = map_data.sort(function (a, b) {
    return b.trials - a.trials;
  })[0];
  const maxTrials = Math.max(trialMaxItem.trials,max_trials);

  var xy = geoEquirectangular();
  var path = geoPath().projection(xy);

  var scaleMarker = scaleLinear().domain([1, maxTrials]).range([3, max_radius]);

  var world_map = select("#graph")
    .insert("svg:svg")
    .attr("width", "100%")
    .attr("viewBox", `0 0 ${w} ${h}`);

  var states = world_map.append("svg:g").attr("id", "states");

  var circles = world_map.append("svg:g").attr("id", "circles");

  var marker_label = world_map.append("svg:g");

  json(countries_json).then(function (collection) {
    states
      .selectAll("path")
      .data(collection.features)
      .enter()
      .append("svg:path")
      .attr("d", path)
      .attr("fill", map_color)
      .attr("stroke", map_color);
    // .on("mouseover", function(d) {
    //     d3.select(this).style("fill","#6C0")
    //         .append("svg:title")
    //         .text(d.properties.name);})
    // .on("mouseout", function(d) {
    //     d3.select(this).style("fill","#ccc");})
  });
  const scalefactor = 1;
  circles
    .selectAll("circle")
    .data(map_data)
    .enter()
    .append("svg:circle")
    .attr("cx", function (d, i) {
      return xy([d.longitude, d.latitude])[0];
    })
    .attr("cy", function (d, i) {
      return xy([d.longitude, d.latitude])[1];
    })
    .attr("r", function (d) {
      let r = d.trials;
      return r ? scaleMarker(r) : 0;
    })
    .attr("fill", "currentColor")
    .attr("class", "text-primary")
    .attr("opacity", "0.4")
    .on("mouseenter", function (d) {
      const popperHeight = 45;
      let r = d.trials;
      let rect = marker_label
        .append("rect")
        .attr("y", xy([d.longitude, d.latitude])[1] - popperHeight)
        .attr("rx", 2)
        .attr("fill", "#fff")
        .attr("stroke", "#ccc");

      let textGroup = marker_label
        .append("g");
      textGroup
        .append("text")
        .attr("dx", 8)
        .attr("dy", "1.6em")
        .attr("font-size", 12)
        .attr("fill", "#777")
        .text(d.country);

      textGroup
        .append("text")
        .attr("dx", 8)
        .attr("dy", "3em")
        .attr("font-size", 12)
        .attr("fill", "#777")
        .text(`${d.trials} trials`);
      
      // const popperWidth = textGroup['_groups'][0][0].getBoundingClientRect().width+8;
      let country_text_width = measureText(`${d.country}`, 12)
      let trial_text_width = measureText(`${d.trials} trials`, 12)
      let popWidth = country_text_width>trial_text_width?country_text_width:trial_text_width
      popWidth>200?popWidth+=40:popWidth+=30
      let translate_x_position = xy([d.longitude, d.latitude])[0] - (popWidth + scaleMarker(r))
      translate_x_position=translate_x_position<0?translate_x_position+(popWidth + 2*scaleMarker(r)):translate_x_position
      textGroup.attr(
        "transform",
        `translate(${
          translate_x_position
        },${xy([d.longitude, d.latitude])[1] - popperHeight})`
      )
      rect.attr("width", popWidth)
      .attr("height", popperHeight)
      .attr(
        "x",
        translate_x_position
      )
    })

    .on("mouseout", function (d) {
      marker_label.select("rect").remove();
      marker_label.select("g").remove();
    });
    setTimeout(function(){
      if(window.innerWidth>1500){
        $($scrollDownButton).fadeIn()
      }
      
    }, 3000)
    $($scrollDownButton).on('click', function(e) { 
      e.preventDefault()
      const elementRect = $('#overview-info-section')[0].getBoundingClientRect().top;
      const bodyRect = document.body.getBoundingClientRect().top
      window.scrollTo({
        'top': elementRect - bodyRect - 80,
        'behavior': 'smooth'
      })
    })
    AOS.init();
});
function measureText(string, fontSize = 10) {
  const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2796875,0.2765625,0.3546875,0.5546875,0.5546875,0.8890625,0.665625,0.190625,0.3328125,0.3328125,0.3890625,0.5828125,0.2765625,0.3328125,0.2765625,0.3015625,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.2765625,0.2765625,0.584375,0.5828125,0.584375,0.5546875,1.0140625,0.665625,0.665625,0.721875,0.721875,0.665625,0.609375,0.7765625,0.721875,0.2765625,0.5,0.665625,0.5546875,0.8328125,0.721875,0.7765625,0.665625,0.7765625,0.721875,0.665625,0.609375,0.721875,0.665625,0.94375,0.665625,0.665625,0.609375,0.2765625,0.3546875,0.2765625,0.4765625,0.5546875,0.3328125,0.5546875,0.5546875,0.5,0.5546875,0.5546875,0.2765625,0.5546875,0.5546875,0.221875,0.240625,0.5,0.221875,0.8328125,0.5546875,0.5546875,0.5546875,0.5546875,0.3328125,0.5,0.2765625,0.5546875,0.5,0.721875,0.5,0.5,0.5,0.3546875,0.259375,0.353125,0.5890625]
  const avg = 0.5279276315789471
  return string
    .split('')
    .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
    .reduce((cur, acc) => acc + cur) * fontSize
}