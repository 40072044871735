export function isBrowserIE11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

export const animationPromise = (fn) =>
  new Promise((resolve, reject) =>
    requestAnimationFrame(function () {
      try {
        return resolve(fn());
      } catch (error) {
        return reject(error);
      }
    }),
  );

export default {
  isBrowserIE11,
  animationPromise,
};
