// function for position sticky component
export default function stickToTop(stickyContainer, stickyItem, position){
    var offset = $(stickyContainer).offset();
    var navParent = $(stickyContainer);
    var nav = navParent.find(stickyItem);
    var tmp = navParent.find(stickyItem).clone().attr('class', 'tmp').css('visibility', 'hidden'); 
    const stickAndClone =()=> {
        if (window.scrollY+parseInt(position,10) > offset.top) {
            navParent.append(tmp);
            let width = nav.outerWidth()
            nav.css({'position': 'fixed', 'top': position, 'width': width});
        } else {
            navParent.find('.tmp').remove();
            nav.css({'position': 'static', 'top': '', width: ''});
        }
    }
    stickAndClone()
    window.addEventListener('scroll', stickAndClone);
}
export function isElementInViewport (el) {

    // if it is a jquery element
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}
export function smoothScrollTo(e, offset){
    e.preventDefault()
    let target = $($(e.target).attr('href'))[0]
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = target.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - (offset - 5); // tolerence of 5px
    window.scrollTo({
      'top': offsetPosition,
      'behavior': 'smooth'
    });
}
export function getScrollSpy(spyOn, spyTarget, offset){
    $(spyOn).scrollspy({ target: spyTarget, offset: offset });
    $(`${spyTarget} a`).on('click', (e)=>smoothScrollTo(e, offset))
}